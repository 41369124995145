/* customize scrollbar */
/* width */
::-webkit-scrollbar {
  width: 0px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f3f4f6;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3f4f6 ;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f3f4f6;
  display: none;
}

.container {
  overflow-x: hidden;
}

.animated-picture-rtl {
  width: 100%;
  transform: rotate(12deg);
  animation: Picture-slide-rigt-to-left .5s ease-in-out forwards;
  left: 0;
}

@keyframes Picture-slide-rigt-to-left {
  0% {
    transform: translateX(200%) rotate(-120deg); /* rotate burada da eklenmeli */
  }
  100% {
    transform: translateX(0%) rotate(12deg); /* rotate burada da eklenmeli */
  }
}

.animated-picture-ltr {
  width: 100%;
  transform: rotate(-5deg);
  animation: Picture-slide-left-to-right .5s ease-in-out forwards;
  left: 0;
}

@keyframes Picture-slide-left-to-right {
  0% {
    transform: translateX(-200%) rotate(120deg); /* rotate burada da eklenmeli */
  }
  100% {
    transform: translateX(0%) rotate(-12deg); /* rotate burada da eklenmeli */
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slide-in {
  animation: slide-in 0.5s ease-in-out forwards;
}

@keyframes slide-out {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.slide-out {
  animation: slide-out 0.5s ease-in-out forwards;
}